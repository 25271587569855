

// https://uxwing.com/png-icon/   free icon


import Cookie from 'js-cookie';
import axios from "axios";

/**
 * CreateTodos : function in the 'Utils' file.
 */
export function createTodos () {

    const todos = [];
    
    //console.log("Inside 'createTodos' function");
    for (let i = 0; i < 5; i++) {
        todos.push({
            id: i,
            text: "Todo #" + i
        });
    }
    sleep();
    return todos;
}


export function sleep(delay = 4000) {
    // link : https://stackoverflow.com/questions/1141302/is-there-a-sleep-function-in-javascript
    let d = new Date().getTime();  
    while (d + delay > new Date().getTime()) {  }
}

export function remove(_arr, _el) {
    
    // The find() method of Array instances returns the first element 
    // in the provided array that satisfies the provided testing function
    var desiredElt = _arr.find(e => e.id === _el.id); 
    
    var eltIndex = _arr.indexOf(desiredElt);
    var deletedElt = _arr.splice(eltIndex, 1);
    
    return deletedElt;
}

// export function getBase64String (imageURL) {

//     // get the "base64 string" of an image 
//     // https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
  
//     var fileReader = new FileReader();
//     fileReader.onload = function(fileLoadedEvent) {
//         var srcData = fileLoadedEvent.target.result; // <--- data: base64

//         var newImage = document.createElement('img');
//         newImage.src = srcData;

//         event.target.files[i].base64 = newImage.outerHTML;
//     }
//     fileReader.readAsDataURL(event.target.files[i]);

// }


export function downloadFileFromLink(fileURL, fileName) {
    
    // The problem in downloading the file was, I was downloading a Docx or an image as PDF.
    // the right way is to respect the "extension" file. otherwise the file will be damaged.

    var link = document.createElement("a");
    link.download = fileName;
    link.href = fileURL;
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link);
}


export function formatDate(_date) {

}

export function formatCurrency() {
    
}


export function getJobCategories() {
        return [
            { Id: 0, Name: '-- Select a Category --', ImageURL : '' },
            { Id: 10, Name: 'Accountant', ImageURL : '' },
            { Id: 20, Name: 'Agriculture', ImageURL : '' },
            { Id: 30, Name: 'Art', ImageURL : '' },
            { Id: 40, Name: 'Business', ImageURL : '' },
            { Id: 50, Name: 'Communications', ImageURL : '' },
            { Id: 60, Name: 'Community and Social Services', ImageURL : '' },
            { Id: 70, Name: 'Construction', ImageURL : '' },
            { Id: 80, Name: 'Education', ImageURL : '' },
            { Id: 90, Name: 'Engineering', ImageURL : '' },
            { Id: 100, Name: 'Farming', ImageURL : '' },
            { Id: 110, Name: 'Finance', ImageURL : '' },
            { Id: 120, Name: 'Food and Serving Related', ImageURL : '' },
            { Id: 130, Name: 'Government', ImageURL : '' },
            { Id: 140, Name: 'Health care', ImageURL : '' },
            { Id: 150, Name: 'Human Resources', ImageURL : '' },
            { Id: 160, Name: 'Information technology', ImageURL : '' },
            { Id: 170, Name: 'Journalist', ImageURL : '' },
            { Id: 180, Name: 'Law', ImageURL : '' },
            { Id: 190, Name: 'Management', ImageURL : '' },
            { Id: 200, Name: 'Manufacturing', ImageURL : '' },
            { Id: 210, Name: 'Marketing', ImageURL : '' },
            { Id: 220, Name: 'Other', ImageURL : '' },            
            { Id: 230, Name: 'Sales', ImageURL : '' },
            { Id: 240, Name: 'Transportation', ImageURL : '' },
    ];
}

export function getEmploymentTypes() {
    return [
        //{ Id: 0, Name: 'test' }, 

        { Id: 10, Name: 'Not Specified' }, 
        { Id: 20, Name: 'Full Time' }, 
        { Id: 30, Name: 'Part Time' }, 
        { Id: 40, Name: 'Freelancing' },
        { Id: 50, Name: 'Contract' },
        { Id: 60, Name: 'Temporary' },
        { Id: 70, Name: 'Volunteer' },
        { Id: 80, Name: 'Internship' }
    ];
}

export function getWorkPlaces() {
    return [
        //{ Id: 0, Name: 'test' }, 

        { Id: 10, Name: 'Not Specified' },
        { Id: 20, Name: 'On-Site' },
        { Id: 30, Name: 'Remotly' },
        { Id: 40, Name: 'Hybrid' },
    ]
};

export function getGenders() {
    return [
        //{ Id: 0, Name: 'test' }, 

        { Id: 10, Name: 'Any'},
        { Id: 20, Name: 'Female'},
        { Id: 30, Name: 'Male'},
    ];
}

export function showLocalTime(itemJob) {

    var datetimeMilli = new Date(itemJob.PostingDate).getTime();
    var d = new Date();
    datetimeMilli += -d.getTimezoneOffset() * 60000; // 1 * timeZoneOffset 
    var dateLocal = new Date(datetimeMilli).toLocaleString('en-US', { hour12: false });
    dateLocal = dateLocal.slice(0, dateLocal.indexOf(","));
    //debugger;
    var monthNb = dateLocal.slice(0, dateLocal.indexOf("/"));
    var monthSt;
    monthNb == 1 ? monthSt = "Jan" :
    monthNb == 2 ? monthSt = "Feb" :
    monthNb == 3 ? monthSt = "Mar" :
    monthNb == 4 ? monthSt = "Apr" :
    monthNb == 5 ? monthSt = "May" :
    monthNb == 6 ? monthSt = "Jun" :
    monthNb == 7 ? monthSt = "Jul" :
    monthNb == 8 ? monthSt = "Aug" :
    monthNb == 9 ? monthSt = "Sep" : 
    monthNb == 10 ? monthSt = "Oct" :
    monthNb == 11 ? monthSt = "Nov" :
    monthSt = "Dec";

    itemJob.PostingDate = monthSt + dateLocal.slice(dateLocal.indexOf("/"));
    //console.log(dateLocal);

    return itemJob;
}

export function showLocalDateAndTime(itemJob) {

    var datetimeMilli = new Date(itemJob.PostingDate).getTime();
    var d = new Date();
    datetimeMilli += -d.getTimezoneOffset() * 60000; // 1 * timeZoneOffset 
    var dateLocal = new Date(datetimeMilli).toLocaleString('en-US', { hour12: false });
    
    dateLocal = dateLocal.replace(",", '');
    
    //console.log(dateLocal);
    //debugger;
    var monthNb = dateLocal.slice(0, dateLocal.indexOf("/"));
    var monthSt;
    monthNb == 1 ? monthSt = "Jan" :
    monthNb == 2 ? monthSt = "Feb" :
    monthNb == 3 ? monthSt = "Mar" :
    monthNb == 4 ? monthSt = "Apr" :
    monthNb == 5 ? monthSt = "May" :
    monthNb == 6 ? monthSt = "Jun" :
    monthNb == 7 ? monthSt = "Jul" :
    monthNb == 8 ? monthSt = "Aug" :
    monthNb == 9 ? monthSt = "Sep" : 
    monthNb == 10 ? monthSt = "Oct" :
    monthNb == 11 ? monthSt = "Nov" :
    monthSt = "Dec";

    itemJob.PostingDate = monthSt + dateLocal.slice(dateLocal.indexOf("/"));

    return itemJob;
}

const decrypt = (salt, encoded) => {
const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};

export function getUserFromCookie(cookie) {
    
    var user = {};
    var cookieAsObject = {};
    var cookieInJsonFormat = null;

    if(cookie) {
        //cookieInJsonFormat = cookie.replaceAll("|||", "\"")
        cookieInJsonFormat = decrypt("salt", cookie);
    
        if(cookieInJsonFormat)
        {
            // after decode it (cookie from client side)
            //token={"ID":26,"Username":"employer","CompanyEmail":"saeedchebli@hiremecominfo.com","AccountType":1}

            // after decode it (cookie from server side)
            //{"user":{"ID":26,"Username":"employer","CompanyEmail":"s.chebli@hotmail.com","AccountType":1}}
            try {
                cookieAsObject = JSON.parse(decodeURIComponent(cookieInJsonFormat));     //set from server side
                //console.log("cookieAsObject", cookieAsObject);
                if(cookieInJsonFormat && cookieAsObject) {
                    user.ID = parseInt(cookieAsObject.ID);
                    user.Username = cookieAsObject.Username;
                    user.CompanyEmail = cookieAsObject.CompanyEmail;
                    user.AccountType = parseInt(cookieAsObject.AccountType);
                    //user.Logo = tempUser.Logo;  // removed because cookie cannot hold more than 4096 caracteres
                }
            } catch (error) {
                // if ba3besing bl cookie, zetto barra
                console.log("Cookie is not valid:", error);
                
                Cookie.remove('token'); // this line only works if the user adds the 'token' manually 

                axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
                .then(res => {
                    if(sessionStorage.getItem('Logo'))
                        sessionStorage.removeItem('Logo');
                    alert("Session has been added or modified manually, please Login again");
                    window.location.href = process.env.REACT_APP_ORIGIN + "/login";
                })
                .catch(err => {
                    //toast('error', err.response.data.message);
                })
            }
        }
    }

    return user;
}

export function isValidToken(encryptedToken) {
    try {
        var decCookie = decrypt("salt", encryptedToken);
        var cookieAsJson = JSON.parse(decCookie);
        console.log("initial cookie", cookieAsJson);
        return true;
    }
    catch(e) {

        Cookie.remove('token'); // this line only works if the user adds the 'token' manually 

        axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
        .then(res => {
            if(sessionStorage.getItem('Logo'))
                sessionStorage.removeItem('Logo');
            alert("Session has been added or modified manually, please Login again");
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
        })
        .catch(err => {
            //toast('error', err.response.data.message);
        })
    }
}
