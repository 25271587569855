
import './LogIn.css';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Footer from '../Footer/Footer';
import Cookie from 'js-cookie';
import FileUploader from '../Uploader/FileUploader';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '../../CustomeHooks/useToast';
import validator from 'validator';

import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';

//import 'dotenv/config';
//import fs from "fs";
//import { MailerSend, EmailParams, Sender, Recipient } from "mailersend";


const LogIn = ({ setLogoFn, sendUsernameFn, userWant }) => {
//const LogIn = ({ setLogoFn, sendUsernameFn }) => {

    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passIsHidden, setPassIsHidden] = useState(true);

    const [emailResetPassword, setEmailResetPassword] = useState('');   // email to send to.
    const [isSending, setIsSending] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    //console.log("document.referrer = ", document.referrer);
    
    //const [backIn, setBackIn] = useState(false);

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_URL}/session`)
    //     .then(res => {
    //         //console.log("session.views=", res.data.views);
    //     });
    //     // if(userWantsToResetPass && userWant) {   
    //     //     setUserWantsToResetPass(false);
    //     // }
    // }, []);

    const logout = () => {

        axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
        .then(res => {
            if(sessionStorage.getItem('Logo'))
                sessionStorage.removeItem('Logo');

            // So once the user logout. refresh the page

            if(!location || !location.pathname)
                //window.location.href = "http://localhost:300x";
                window.location.href = process.env.REACT_APP_ORIGIN;
            else {
                if(location.pathname === '/admin')
                    //window.location.href = "http://localhost:300x";
                    window.location.href = process.env.REACT_APP_ORIGIN;
                else  // common section (to write inside a class)
                    if(location.pathname == "/search" && localStorage.getItem('search'))
                        //window.location.href = "http://localhost:300x" + location.pathname + localStorage.getItem('search');
                        window.location.href = process.env.REACT_APP_ORIGIN + location.pathname + localStorage.getItem('search');
                    else {
                        if(location.pathname === '/signup')
                            //window.location.href = "http://localhost:300x";    
                            window.location.href = process.env.REACT_APP_ORIGIN;    
                        else
                            //window.location.href = "http://localhost:300x" + location.pathname;
                            window.location.href = process.env.REACT_APP_ORIGIN + location.pathname;
                    }
            } 
        })
        .catch(err => {
            toast('error', err.response.data.message);
        })

        return;

/*
        // the path attribute takes you to the desired destination after deleting the cookie
        //Cookie.remove('token', { path: '/', domain: 'localhost' });  
        Cookie.remove('token');

        if(localStorag)
            localStorag.removeItem('Logo');


        // We commented this line, because we want to leave the user in the same page. 
        // and not redirect him to another page
        //navigate('/login');
        
        // So once the user logout. refresh the page
        
        if(!location || !location.pathname)
            //window.location.href = "http://localhost:300x";
            window.location.href = process.env.REACT_APP_ORIGI;
        else {
            if(location.pathname === '/admin')
                //window.location.href = "http://localhost:300x";
                window.location.href = process.env.REACT_APP_ORIGI;
            else
                if(location.pathname == "/search" && localStorage.getItem('search'))
                    //window.location.href = "http://localhost:300x" + location.pathname + localStorage.getItem('search');
                    window.location.href = process.env.REACT_APP_ORIGI + location.pathname + localStorage.getItem('search');
                else {
                    if(location.pathname === '/signup')
                        //window.location.href = "http://localhost:300x";    
                        window.location.href = process.env.REACT_APP_ORIGI;    
                    else
                        //window.location.href = "http://localhost:300x" + location.pathname;
                        window.location.href = process.env.REACT_APP_ORIGI + location.pathname;
                }
        }

        return;

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        //fetch('http://localhost:808x/logout', {
        //fetch(process.env.REACT_APP_URL + '/logout', {
        fetch(`${process.env.REACT_APP_URL}/logout`, {
            method: 'GET',
            mode: 'no-cors',
            redirect: 'follow',
            credentials: 'include',
            headers: headers
        })
        .then((res) => {
            localStorag.removeItem('Logo');
            // We commented this line, because we want to leave the user in the same page. 
            // and not redirect him to another page
            //navigate('/login');
            
            // So once the user logout. refresh the page
            
            if(!location || !location.pathname)
                //window.location.href = "http://localhost:300x";
                window.location.href = process.env.REACT_APP_ORIGI;
            else {
                if(location.pathname === '/admin')
                    //window.location.href = "http://localhost:300x";
                    window.location.href = process.env.REACT_APP_ORIGI;
                else
                    if(location.pathname == "/search" && localStorage.getItem('search'))
                        //window.location.href = "http://localhost:300x" + location.pathname + localStorage.getItem('search');
                        window.location.href = process.env.REACT_APP_ORIGI + location.pathname + localStorage.getItem('search');
                    else {
                        if(location.pathname === '/signup')
                            //window.location.href = "http://localhost:300x";    
                            window.location.href = process.env.REACT_APP_ORIGI;    
                        else
                            //window.location.href = "http://localhost:300x" + location.pathname;
                            window.location.href = process.env.REACT_APP_ORIGI + location.pathname;
                    }
            }
        })
        */
    }

    const [file, setFile] = useState();
    const getIsAddingCV = (_isAdding) => {
        //console.log("adding = ", _isAdding);
        //setIsAddingCV(_isAdding);
    }
    const xx = (_files) => {
        //console.log('file as att = ', _files[0]);

        setFile(_files[0]);
    }

    const [img64, setImg64] = useState('');

    axios.defaults.withCredentials = true;

    // const handleSubmit = async (e) => {
    const Login = () => {

        //e.preventDefault();
        // There is a condition on "Back End" level as well.
        if(Cookie.get('token')) {
            toast("success", "You are already logged in");
            return;
        }

        if(!username || (username && username.trim().length === 0)){
            toast("warning", "Username is required");
            return;
        }

        if(!password || (password && password.trim().length === 0)){
            toast("warning", "Password is required");
            return;
        }
        var user = {
            Username: username,
            Password: password
        }
        setIsLoggingIn(true);

        axios.post(`${process.env.REACT_APP_URL}/login`, user, { withCredentials:true })
        .then(res => {
            //console.log("res=", res);
            if(res.data.status === "success") {
                
                // To Update my code, Send all cookie value once user logs in, and save it in the browser using Cookie.set.
                // Then I can use the Utile.js function.

                //console.log("document.cookie", document.cookie);                // works exactly as I want
                //console.log("Cookie.get('token')", Cookie.get('token'));      // works exactly as I want
            
                // Cookie.remove('token');
                // Cookie.set('token', 
                //             JSON.stringify(res.data.user), 
                //             { 
                //                 expires: new Date(new Date().getTime() + 60 * 60 * 1000),    // + time in MilliSeconds 
                //                 secure: true // has no effect on "403 forbidden error"
                //             });
                //Cookie.set('token', res.data.user);  set it like: [object, object]

                
                // If the cookie has expired by itself, the Logo will still there, so remove it.
                if(sessionStorage.getItem('Logo'))
                    sessionStorage.removeItem('Logo');

                sessionStorage.setItem('Logo', res.data.Logo);
                    
                if(res.data.isAdmin) {
                    navigate("/admin");
                }
                else {

                    if(location.state) {
                        if(location.state.LastVisitedPage === "/search" && localStorage.getItem('search')) {
                            navigate(location.state.LastVisitedPage + localStorage.getItem('search'));
                        }
                        else {
                            if(location.state.LastVisitedPage === '/signup' ||
                               location.state.LastVisitedPage === '/login' ) {
                                navigate("/");
                            }
                            else {
                                navigate(location.state.LastVisitedPage);
                            }
                        }
                    }
                    else
                        navigate("/");
                }
                //window.location.href = "http://localhost:X000/";
            }
            else { // login failed : Wrong Username or Password.
                toast("error", res.data.message);
            }
        })
        .catch(err => {
            console.log("err = ", err);

            //if(err.response.status === 401 || err.response.status === 500) {
                //toast("error", err.response.data.message);
            //}
        })
        .finally(() => {
            setIsLoggingIn(false);
        });

        
        // axios({
        //     method: 'POST',
        //     url: process.env.REACT_APP_URL + '/login',
        //     data: JSON.stringify(user),
        //     headers: { "Content-Type": "application/json" }
        // });
        // return;

        // const mailerSend = new MailerSend({
        //     apiKey: "mlsn.4902ee81093d723a290061f907c2b6e5084743c4e0602f5172f5866923e3b66b",
        // });

        // //const sentFrom = new Sender("you@yourdomain.com", "Your name");
        // const sentFrom = new Sender("you@trial-jpzkmgq29pvg059v.mlsender.net", "Your name");

        // const recipients = [
        //     new Recipient("s.chebli@hotmail.com", "Your Client")
        // ];

        // // const attachments = [
        // // new Attachment(
        // //     fs.readFileSync('/path/to/file.pdf', { encoding: 'base64' }),
        // //     'file.pdf',
        // //     'attachment'
        // // )
        // // ]

        // const emailParams = new EmailParams()
        // .setFrom(sentFrom)
        // .setTo(recipients)
        // .setReplyTo(sentFrom)
        // //.setAttachments(attachments)
        // .setSubject("This is a Subject")
        // .setHtml("<strong>This is the HTML content</strong>")
        // .setText("This is the text content");

        // mailerSend.email.send(emailParams)
        // .then(response => {
        //     console.log("Email sent successfully:", response);
        // })
        // .catch(error => {
        //     console.error("Error sending email:", error);
        // })

        // Reference : https://malcoded.com/posts/react-http-requests-axios/
        // ***************************************
        // ************** XMLHttpRequest *********
        // ***************************************

        // does not set cookie on live react.js
        // set cookie-header does not set the "same-site" attriute
        // var xhr = new XMLHttpRequest();
        // // open the request with the verb and the url
        // xhr.onreadystatechange = function() {
        //     if (xhr.readyState == XMLHttpRequest.DONE) {
        //         //console.log("using XMLHttpRequest 1 = ", JSON.parse(xhr.response).user.Logo);
        //         console.log("using XMLHttpRequest 1 = ", xhr.response);
        //         //localStorage.setItem('Logo', JSON.parse(xhr.response).user.Logo);
        //         setIsLoggingIn(false);
        //     }
        // }
        // xhr.open('POST', `${process.env.REACT_APP_URL}/login`)
        // xhr.setRequestHeader("Content-Type", "application/json");
        
        // // This line is used to set the cookie, which was the biggest problem for me in XMLHttpRequest 
        // xhr.withCredentials = true;

        // //xhr.setRequestHeader("Access-Control-Allow-Origin", "http://localhost:3000")
        // xhr.setRequestHeader("Access-Control-Allow-Origin", "https://hiremeworld.com/")
        // //xhr.setRequestHeader("Content-Type", "application/json");
          
        // // send the request
        // xhr.send(JSON.stringify(user));

        // return;

        /*
        // give 403: on Live React.js
        //has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource
        var headers = new Headers();
        //headers.append('Access-Controll-Allow-Methods', "Get, Head, Post");
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        //headers.append('Content-Type', 'text/html');
        headers.append('Access-Control-Allow-Origin', process.env.REACT_APP_ORIGI);  // has no effect
        //headers.append('User-Agent', 'user-agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36');

        fetch(`${process.env.REACT_APP_URL}/login`, {
            method: 'POST',
        
            // React live + node live: (cors XXX), 
            mode:  'no-cors',    // required
            //redirect: 'follow',
            credentials: 'include',   //'include', // Don't forget to specify this if you need cookies
            //headers: headers,
             headers: {
                 'Access-Control-Allow-Origin': process.env.REACT_APP_ORIGI
             },
            body: JSON.stringify(user),
            //body: "this is Allah fucking himself", 
        })
        .then(res => {
            console.log("res fetch=", res);
        })

        //axios.post('http://localhost:808x/login', user)     // to del
        //axios.post('https://hiremeworld.com/login', user, {headers:{"Content-Type" : "application/json"}})    // to del
        //axios.post('https://hiremeworld.com/login', user)   // to del
    
        axios.defaults.withCredentials = true;
        axios.defaults.headers['X-API-KEY'] = '123456';

        axios.post(process.env.REACT_APP_URL + '/login', user
            //  , {
            //      headers: {
            //          'Content-Type': 'application/json',
            //          //Accept: "application/json",Access-Control-Allow-Origin
            //          //'Access-Control-Allow-Origin': "http://localhost:3000/"
            //      }
            // }
        )
        //axios.post(process.env.REACT_APP_URL + '/login', user, { withCredentials: true })
        .then(res => {
            console.log("res.data = ", res.data);
            if(res.data.status === "success") {
                
                if(res.data.isAdmin) {
                    navigate("/admin");
                }
                else {
                    //localStorage.setItem('Logo', res.data.user.Logo);
                    localStorage.setItem('Logo', res.data.Logo);

                    if(location.state) {
                        if(location.state.LastVisitedPage === "/search" && localStorage.getItem('search')) {
                            navigate(location.state.LastVisitedPage + localStorage.getItem('search'));
                        }
                        else {
                            if(location.state.LastVisitedPage === '/signup' ||
                               location.state.LastVisitedPage === '/login' ) {
                                navigate("/");
                            }
                            else {
                                navigate(location.state.LastVisitedPage);
                            }
                        }
                    }
                    else
                        navigate("/");
                }
                //window.location.href = "http://localhost:X000/";
            }
        })
        .catch(err => {
            console.log("err = ", err);

            if(err.response.status === 401 || err.response.status === 500) {
                toast("error", err.response.data.message);
            }
        })
        .finally(() => {
            setIsLoggingIn(false);
        });
        */

        
        // This block of code is working correctly
        /*var reader = new FileReader();
         reader.onloadend = function() {
             console.log('RESULT base64=', reader.result);
             setImg64(reader.result);

             console.log("file", file);
      
             console.log("reader.result.split('base64,')[1]", reader.result.split("base64,")[1]);

            var formData = new FormData();
            formData.append("fileName", file);
            formData.append("URL", file.URL);
            
            var formData = { _fileName: file.name, _URL: file.URL }
        
        }
        // axios.post('https://hiremeworld.com/Upload_File', formData)
        // .then((res) => {
        //         console.log("res=", res);
        //     })
        //     .catch(err => {
        //         console.log("err=", err);
        //     })
        //     .finally(() => {
        //     });
        //}
        reader.readAsDataURL(file);
        return;*/


        /*
        const buffer = await file.arrayBuffer();
        let byteArray = new Int8Array(buffer);
        console.log(byteArray);
        //var f = new File(byteArray, 'filename.PNG', {type: 'image/png'});
        //f.URL = URL.createObjectURL(f);
        setImg64(file.URL);
        //console.log(f);
        return; */
    }

    const backToLogin = () => {
        setUserWantsToResetPass(false);
    }
     
    const [userWantsToResetPass, setUserWantsToResetPass] = useState(false);

    const forgotPassword = (e) => {
        e.preventDefault();

        if(!username || username.trim() === '') {
            toast('warning', 'Please enter your username.');
            return;
        }

        setUserWantsToResetPass(true);

        sendUsernameFn(username.trim());

        //navigate('/forgotpassword');
    }

    const resetPassword = (e) => {
        e.preventDefault();

        if(!emailResetPassword) {
            toast('warning', 'Email is required');
            return;
        }

        if(!validator.isEmail(emailResetPassword)) {
            toast("warning", "Invalid Email !");
            return;
        }
  
        //console.log("in forgot pass", emailResetPassword);

        var data = {
            Username: username,   // if null => the type on the server side will be "Object" and not string
            Email: emailResetPassword,
        };
        setIsSending(true);

        //console.log("data=", data);

        axios.defaults.withCredentials = true;
        //axios.post('http://localhost:808x/forgot_password', data)
        axios.post(process.env.REACT_APP_URL + '/forgot_password', data)
        .then((res) => {
            console.log("inside then", res);

            if(res.data.status && res.data.status !== "success") {
                toast('error', res.data.message);
            }
            else {   
                if(res.data === 'success') {
                    toast('success', "The new password has been sent to the email");
                    setUserWantsToResetPass(true);
                }
                else {
                    toast('error', "Error in sending password, Please try again !");
                }
            }
        })
        .catch((err) => {
            //console.log("inside catch", err);
            //if(err.response.status === 401) {
            toast('error', err.response.data.message);
            //}
        })
        .finally((e) => {
            setIsSending(false);
        })
    }


    const toggleVisibility = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
          x.type = "text";
          setPassIsHidden(false);
        } else {
          x.type = "password";
          setPassIsHidden(true);
        }
    }


    return(<>

        <div className="login-container every-page-container">
    

        {/* <FileUploader XXX={xx} 
                    isAddingFn={ getIsAddingCV } 
                    _accept=".zip, .pdf, .docx"
                    /> 
        <div>
        { 
            img64 && <img src={img64} alt="test" />
        }
        </div>
        
        */}


        {
            // !document.cookie ?
            !Cookie.get('token') ?
            <>
                
                    <div className="container-fluid">
                        <div className='row'>
                            
                            <div className='left-side'>
                                <div className='twm-log-reg-media'>
                                    <div className='twm-l-media'>
                                        <img src="/login-bg.png" alt='login-bg' />
                                    </div>
                                </div>
                            </div>


                        
                            <div className='page-loader'>
                                <div className='twm-log-reg-form-wrap'>
                                    <div className='twm-log-reg-logo-head'></div>
                                    <div className='loginid'></div>
                                    <div className='twm-log-reg-inner'>
                                        <div className='twm-log-reg-head'>
                                            <div className='twm-log-reg-logo'>
                                                <span className='log-reg-form-title'>
                                                    {
                                                        !userWantsToResetPass ? 'Log In' : 
                                                        username && userWantsToResetPass ? 'Reset Password' : ''
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className='twm-tabs-style-2'>
                                            <div className='tab-content'>
                                                <div className='tab-pane'>
                                                    <div className='row'>


                                                        {/* <form onSubmit={ handleSubmit }> */}
                                                        <form>

                                                            {/* Login page*/}

                                                            { 
                                                                !userWantsToResetPass && 
                                                                <div className='login-fields'>
                                                                    <div className="element">
                                                                        <input type="text" 
                                                                            name="username" 
                                                                            className="input-field"
                                                                            value= { username }
                                                                            maxLength='50'
                                                                            placeholder='Username (required)' 
                                                                            onChange={ (e) => setUsername(e.target.value) }/>
                                                                    </div>

                                                                    <div className="element" style={{ position: 'relative' }}>

                                                                        {
                                                                            passIsHidden ? 
                                                                            password && 
                                                                            <i className="fa fa-eye show-hide-eye" 
                                                                                    aria-hidden="true"
                                                                                    onClick={ toggleVisibility }></i>
                                                                            :
                                                                            password && 
                                                                            <i className="fa fa-eye-slash show-hide-eye" 
                                                                                aria-hidden="true"
                                                                                onClick={ toggleVisibility }></i>
                                                                        }


                                                                        <input type="password" 
                                                                            name="password"
                                                                            id='password'
                                                                            maxLength='30'
                                                                            className="input-field"
                                                                            value={ password }
                                                                            placeholder='Password (required)' 
                                                                            onChange={ (e) => setPassword(e.target.value) }/>
                                                                    </div>

                                                                    <div className="element">
                                                                        <button className='forgot-password' 
                                                                                name='forgotpassword'
                                                                                type='button'
                                                                                onClick={ forgotPassword }>Forgot Password ?</button>
                                                                    </div>

                                                                    <div className='signup-login element'>
                                                                        <div className="signup-div">
                                                                            { 
                                                                                !isLoggingIn && 
                                                                                <Link to='/signup' className='signup-submit'>
                                                                                {/* Create Account */}
                                                                                Sign Up
                                                                                </Link>
                                                                            }
                                                                        </div>
                                                                        <div className="login-div">
                                                                            
                                                                        <input name='login'
                                                                                // type='submit' 
                                                                                type='button'
                                                                                onClick={ Login }
                                                                                className={`login-submit${isLoggingIn ? ' disabled' : ''}`}
                                                                                value={`${isLoggingIn ? 'Logging In' : 'Log In'}`} 
                                                                                disabled={ isLoggingIn } />

                                                                            
                                                                            {/* <input className="login-submit" 
                                                                                type="submit" 
                                                                                name="login" 
                                                                                value="Log In" /> */}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* Reset Password page*/}
                                                                                                            
                                                            {
                                                                username && userWantsToResetPass &&
                                                                <div className='three-rows'>
                                                                    
                                                                    <div className='email-div'>
                                                                        <input name="email" 
                                                                                type="text"
                                                                                placeholder='Email (required)'
                                                                                maxLength='50'
                                                                                className='email-itself'
                                                                                value={ emailResetPassword }
                                                                                onChange={ (e) => setEmailResetPassword(e.target.value) }/>
                                                                    </div>

                                                                    <div className='back-to-login-div'>
                                                                        <button className='back-to-login-itself' 
                                                                                name='backToLogin' 
                                                                                onClick={ backToLogin }>
                                                                                Back To Login
                                                                        </button>
                                                                    </div>

                                                                    <div className='reset-password-div'>
                                                                        
                                                                         <button name='resetpassword'
                                                                                type='button'
                                                                                className={`reset-password-itself${ isSending ? ' disabled' : ''}`}
                                                                                disabled={ isSending }
                                                                                onClick={ resetPassword }>
                                                                         { 
                                                                            isSending ? 
                                                                            'Sending ...' : 
                                                                            'Send me the new Password'
                                                                         }

                                                                         </button>
                                                                        
                                                                        
                                                                        {/* {
                                                                            !isSending && 
                                                                            <button className='reset-password-itself'
                                                                                    name='resetpassword' 
                                                                                    onClick={ resetPassword }>
                                                                                    Send me the new Password
                                                                            </button>
                                                                        }*/}
                                                                    </div>

                                                                </div>
                                                            }

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className='footer'>
                        <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
                        <div style={{ color: 'rgb(165 161 161)',
                                    marginLeft: '10%',
                                    marginTop: '18px',
                                    marginBottom: '18px' }}>
                            Copyright © { new Date().getFullYear() } All rights reserved.
                        </div>
                    </div>           
             </>   
            :
            <>
            <div style={{
                    // height: '440px',
                    height: 'calc(100vh - 60px)',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                                    }}>
                <div className="loggedin-block">
                    You are logged in. <u><button onClick={ logout } className='back'>Log out</button></u>
                </div>
            </div>
            <div className='footer' style={{ position: 'absolute',
                                             bottom: '0'}}>
                <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
                <div style={{ color: 'rgb(165 161 161)',
                            marginLeft: '10%',
                            marginTop: '18px',
                            marginBottom: '18px', verticalAlign: 'bottom', }}>
                    Copyright © { new Date().getFullYear() } All rights reserved.
                </div>
            </div></>
        }

        {/* <div className='footer'>
            <hr width='80%' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '58px', }} />
            <div style={{ color: 'rgb(165 161 161)',
                        marginLeft: '10%',
                        marginTop: '18px',
                        marginBottom: '18px' }}>
                Copyright © { new Date().getFullYear() } All rights reserved.
            </div>
        </div> */}


        </div>
    </>
    )
}


export default LogIn;