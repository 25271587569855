


import { useState, useEffect, useRef } from 'react';
import useFetch from '../../CustomeHooks/useFetch';
import './Search.css';
import { Route, Link } from 'react-router-dom';

import JobsList from '../../aImages/jobslist_2.jpg';
import JobItem from '../JobItem/JobItem';
import axios from 'axios';

import Footer from '../Footer/Footer';

import { useSearchParams, useNavigate } from 'react-router-dom';

import ServerCall from '../ServerCall/ServerCall';
import { isValidToken } from '../../Javascript_Functions_to_export/Utils';

import Cookie from 'js-cookie';

import { getEmploymentTypes, 
         getWorkPlaces, 
         getGenders, 
         getJobCategories  } from '../../Javascript_Functions_to_export/Utils';

import { useToast } from '../../CustomeHooks/useToast';

import { useLocation } from 'react-router-dom';

const Search = () => {

    const toast = useToast();

    function handleClick() {
        //toastRef.current.showToast("Logged in succesfully")
        // toastRef2.current.showToast("Logged in succesfully")
    }

    const location = useLocation();

    //_________________________Search criteria block_____________________________
    
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [recentlyJobs, setRecentlyJobs] = useState("Here are the Recently Jobs");
    //_________________________Search criteria block_____________________________


    // Drop Downs list Values
    const [categories, setCategories] = useState();
    const [employmentTypes, setEmploymentTypes] = useState();
    const [workPlaces, setWorkPlaces] = useState();
    const [genders, setGenders] = useState();


    // Search criteria
    const [category, setCategory] = useState(0);    // IT|Sales|Teaching|Other.
    const [employmentType, setEmploymentType] = useState(10); 
    const [workplace, setWorkplace] = useState(10);
    const [gender, setGender] = useState(10);
    const [postedDate, setPostedDate] = useState(0);

    const userClicksSearch = useRef(false);

    //console.log("Search component rendered ...");
    //debugger;


    useEffect(() => {
        //console.log("inside the useEffect hook");

        var cat = 0, empType = 10, workplace = 10, gender = 10, postedDate = 0;
        if(searchParams.size != 0) {

            if(searchParams.get('category')) {
                cat = searchParams.get('category');
                setCategory(searchParams.get('category'));
            }

            if(searchParams.get('employmentType')) {
                empType = searchParams.get('employmentType');
                setEmploymentType(searchParams.get('employmentType'));
            }

            if(searchParams.get('workplace')) {
                workplace = searchParams.get('workplace');
                setWorkplace(searchParams.get('workplace'));
            }

            if(searchParams.get('gender')) {
                gender = searchParams.get('gender');
                setGender(searchParams.get('gender'));
            }

            if(searchParams.get('postedDate')) {
                postedDate = searchParams.get('postedDate')
                setPostedDate(searchParams.get('postedDate'));
            }
        
            //var Url = 'http://localhost:808x/students/' + 
            var Url = process.env.REACT_APP_URL + '/students/' + 
                      cat + "/" + 
                      empType + "/" + 
                      workplace + "/" + 
                      gender + "/" + 
                      postedDate; 

            //console.log("Url = ", Url);

            // if there are search params, then set url again to fetch data based on these search params.
            setUrl(Url);

            // remove "Recently jobs" in case there is any param, || user fetched data 90 days ago
            if(recentlyJobs)
                setRecentlyJobs(null);
        }
        else {
            if(searchParams.size === 0) {
                //setUrl('http://localhost:808x/students/0/10/10/10/1');
                setUrl(process.env.REACT_APP_URL + '/students/0/10/10/10/1');

                setRecentlyJobs("Here are the Recently Jobs");

                setCategory(0);
                setEmploymentType(10); 
                setWorkplace(10);
                setGender(10);
                setPostedDate(0);
            }
        }

        setData(null);

    //}, [searchParams.size]);
    }, [searchParams]);
    

    useEffect(() => {
        /*axios.get(`${process.env.REACT_APP_URL}/touch_session`)
        .then(res => {
            console.log("sessionEnded=", res.data.sessionEnded, "expire=", res.data.expire);
            if(res.data.sessionEnded && res.data.sessionEnded === true) {
                console.log("should logout");
                alert("Session has expired.")
                if(localStorag.getItem('Logo'))
                    localStorag.removeItem('Logo');
                window.location.href = process.env.REACT_APP_ORIGI + "/login";
            }
        });*/

        //console.log("inside the useEffect hook");
        setCategories(getJobCategories());
        setEmploymentTypes(getEmploymentTypes()); 
        setWorkPlaces(getWorkPlaces());
        setGenders(getGenders());

        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }

        if(localStorage.getItem('CurrentPage')) {
            getCorrentItems(parseInt(localStorage.getItem('CurrentPage')));
            //getCorrentItems(2);
        }

        if(!localStorage.getItem('search')) {
            //setUrl('http://localhost:808x/students/0/0/0/0/1');
            setUrl(process.env.REACT_APP_URL + '/students/0/0/0/0/1');
        }

        userClicksSearch.current = false;

    }, []);

    //____________________Once the user opens the "Search" page, display all recently jobs_____________ 
    const [data, setData] = useState(null);

    const [url, setUrl] = useState();

    const { lsData, isLoading, itemsNb } = useFetch(url);
        
    useEffect(() => {

        if(lsData && lsData.length > 0) {

            setLinksNb(Array.from({ 
                length: Math.ceil(lsData.length / jobsPerPage)
                //length: Math.ceil(90 / jobsPerPage)
            }, (_, i) => i + 1));   //=> [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

            // *********** new code **********
            var activeLink = 1;
            var _linksNb = Array.from({ 
                length: Math.ceil(lsData.length / jobsPerPage)
            }, (_, i) => i + 1);

            //The bug was : Set "CurrentPage" = 3, then fetch the recently jobs => nothing appears
            if(parseInt(localStorage.getItem('CurrentPage')) > _linksNb.length)
                localStorage.setItem('CurrentPage', 1);

            if(localStorage.getItem('CurrentPage'))
                activeLink = parseInt(localStorage.getItem('CurrentPage'));

            if(activeLink !== _linksNb.length + 1)
                setData([...lsData.slice((activeLink - 1) * jobsPerPage, activeLink * jobsPerPage)]);
            else
                setData([...lsData.slice((activeLink - 1) * jobsPerPage)]); 
        }
        else {
            setData([]);
        }

        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        // https://stackoverflow.com/questions/5007530/how-do-i-scroll-to-an-element-using-javascript

        let width = window.innerWidth;
        // if user uses mobile
        if(width <= 500 && userClicksSearch.current === true) {
        //if(width <= 500) {  // for testing in localhost

            // var element = document.getElementById('resultData');
            // var headerOffset = 100;
            // var elementPosition = element.getBoundingClientRect().top;
            // var offsetPosition = elementPosition + window.scrollY - headerOffset;
            
            window.scrollTo({
                top: 800,
                behavior: "smooth"
            });
            
            return;

            const element2 = document.getElementById("resultData");
            if(element2)
                element2.scrollIntoView({ behavior: "smooth" });
            // else {
            //     const element2 = document.getElementById("emptyResult");
            //     if(element2)
            //         element2.scrollIntoView({ behavior: "smooth" });
            // }
        }

    }, [lsData]);

    const getCorrentItems = (linkNumber) => {
        if(lsData) {

            if(linkNumber !== linksNb.length + 1)
                setData([...lsData.slice((linkNumber - 1) * jobsPerPage, linkNumber * jobsPerPage)]);
            else
                setData([...lsData.slice((linkNumber - 1) * jobsPerPage)]); 
        
            //console.log(" inside current items ");
            setActiveLink(linkNumber);
        
            localStorage.setItem('CurrentPage', linkNumber);
        }
     }


    // _____________  Pagination Block START ______________________________

    const [activeLink, setActiveLink] = useState(1);
    const [jobsPerPage, setJobsPerPage] = useState(10);
    const [linksNb, setLinksNb] = useState([]);

    // _____________  Pagination Block END ________________________________


    //const [toast, setToast] = useState(null);
    
    // When user clicks "Search Jobs" button
    const handleSubmit = (e) => {

        e.preventDefault();

        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }

        // If user closes the tab || 
        //         closes the browser ||
        //         or open a new tab
        // before the session expired
        // if(_token && !sessionStorage.getItem('Logo')) {
        //     axios.get(`${process.env.REACT_APP_URL}/getLogo`, { withCredentials: true })
        //     .then(res => {
        //         if(sessionStorage.getItem('Logo'))
        //             sessionStorage.removeItem('Logo');

        //         sessionStorage.setItem('Logo');


        //         //window.location.href = process.env.REACT_APP_ORIGI + "/login";
        //     })
        //     .catch(err => {
        //         toast('error', err.response.data.message);
        //     })
        // }

        // if(isSearching === true) { // instead of disabled = true|false
        //     return;
        // }
        
        // setIsSearching(true);
        //setIsSending(true);

        //var newUrl = 'http://localhost:808x/students/' + 
        var newUrl = process.env.REACT_APP_URL + '/students/' + 
                    category + '/' +
                    employmentType + '/' + 
                    workplace + '/' +
                    gender + '/' +
                    postedDate;
        
        if(url === newUrl) {
            toast('warning', 'You are searching with the same criteria !');
            return;
        }

        if(parseInt(category) < 10) {
            toast('warning', 'Please select a category !');
            return;
        }

        localStorage.setItem('CurrentPage', 1);
        setActiveLink(1);
        setData(null);
        
        //console.log("newUrl = ", newUrl);
        setUrl(newUrl);

        if(recentlyJobs)
            setRecentlyJobs(null);

        var linkToNavigate = "/search?";
        if(category > 0) 
            linkToNavigate += "category=" + category + "&";
        if(employmentType > 10)
            linkToNavigate += "employmentType=" + employmentType + "&";
        if(workplace > 10)
            linkToNavigate += "workplace=" + workplace + "&";
        if(gender > 10)
            linkToNavigate += "gender=" + gender + "&";
        if(postedDate > 10)
            linkToNavigate += "postedDate=" + postedDate + "&";

        // Store the "Search Criteria"
        var indexOfQuestionMark = linkToNavigate.lastIndexOf('?');
        var searchQuery = linkToNavigate.slice(indexOfQuestionMark);
        if(searchQuery[searchQuery.length - 1] === "&")
            searchQuery = searchQuery.slice(0, searchQuery.length - 1);
        if(searchQuery === "?")
            localStorage.setItem('search', '?category=0&employmentType=10&workplace=10&gender=10&postedDate=0');
        else
            localStorage.setItem('search', searchQuery);

        // if linkToNavigate still = "/search?", remove the "?"
        if(linkToNavigate === "/search?")
            linkToNavigate = "/search?category=0&employmentType=10&workplace=10&gender=10&postedDate=0";

        // if linkToNavigate ends with "&", remove it.
        if(linkToNavigate[linkToNavigate.length - 1] === "&")
            linkToNavigate = linkToNavigate.slice(0, linkToNavigate.length - 1);

        userClicksSearch.current = true;

        navigate(linkToNavigate);

    }

    //console.log("search component");

    return(
    <div className='search-container every-page-container'>

        {/* { paramCategory } */}
        <div>
            <img src={ JobsList } className="top-img" alt='jobs list' />
        
            <div className='blue-div'>
                Jobs List
            </div>
            <div className='home-jobslist'>
                Home &gt; Jobs List
            </div>
        </div>


{/* ___________________________ "Recently Jobs" message _________________________________________ */}
        <div className="recently-jobs" style={{ 
                marginRight: '27%',
                marginLeft: 'auto',
                color: 'rgb(193 193 193)',
                position: 'relative',
                width: '211px',
                fontStyle: 'italic',
                height: '27px',
                marginBottom: '-24px',
                marginTop: '22px',
                visibility: recentlyJobs ? 'visible' : 'hidden' 
            }}>
            { !isLoading && recentlyJobs }
        </div> 
{/* ___________________________ "Recently Jobs" message _________________________________________ */}


        <div className="real-page-content">
            <div className='search-fields-area' id="resultData">
                <form onSubmit={ (e) => handleSubmit(e) }>

                {/* <input type="submit"
                       className="search-btn"
                       value={ isLoading === true ? 'Searching ...' : 'Search Jobs' } 
                       style={{ visibility: isLoading === true ? 'hidden' : 'visible' }}/> */}

                <input name='search_jobs_list'
                        type='submit' 
                         className={`search-btn ${isLoading === true ? 'disabled' : ''}`}
                         value={`${isLoading === true ? 'Searching...' : 'Search Jobs'}`} 
                         disabled={ isLoading === true } />



                    <div>    
                        <div className='search-field-title'>Category</div>
                        <div>
                                <select onChange={ (e) => setCategory(e.target.value) } value={ category }>
                                {/* <option value='0'>-- Select a Category --</option> */}
                                {
                                    categories && 
                                    Array.isArray(categories) &&
                                    categories.map((category, index) => {
                                        return(
                                            <option key={index} 
                                                    value={ category.Id }>
                                                        { category.Name }
                                            </option>                                        
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div>
                        <div className='search-field-title'>Employment Type</div>
                        <div>                  
                            <select onChange={ (e) => setEmploymentType(e.target.value) } value={ employmentType }>
                            {
                                    employmentTypes && 
                                    Array.isArray(employmentTypes) &&
                                    employmentTypes.map((type, index) => {
                                        return(
                                            <option key={index} 
                                                    value={ type.Id }>
                                                        { type.Name }
                                            </option>                                        
                                        )
                                    })
                            }
                            </select>
                        </div>          
                    </div>

                    <div>
                        <div className='search-field-title'>Workplace</div>
                        <div>                  
                            <select onChange={ (e) => setWorkplace(e.target.value) } value={ workplace }>
                            {
                                    workPlaces && 
                                    Array.isArray(workPlaces) &&
                                    workPlaces.map((work, index) => {
                                        return(
                                            <option key={index} 
                                                    value={ work.Id }>
                                                        { work.Name }
                                            </option>                                        
                                        )
                                    })
                            }
                            </select>
                        </div>          
                    </div>

                    <div>
                        <div className='search-field-title'>Gender</div>
                        <div>                  
                            <select onChange={ (e) => setGender(e.target.value) } value={ gender }>
                            {
                                    genders && 
                                    Array.isArray(genders) &&
                                    genders.map((gender, index) => {
                                        return(
                                            <option key={index} 
                                                    value={ gender.Id }>
                                                        { gender.Name }
                                            </option>                                        
                                        )
                                    })
                            }
                            </select>
                        </div>          
                    </div>

                    <div>
                        <div className='search-field-title'>Date Posted</div>
                        <div>                 
                            <select onChange={ (e) => setPostedDate(e.target.value) } value={ postedDate }>
                                <option value='0'>All</option>
                                <option value='1'>Yesterday</option>
                                <option value='3'>Last 3 days</option>
                                <option value='7'>Last week</option>
                                <option value='14'>Last 2 weeks</option>
                                <option value='30'>Last month</option>
                            </select>
                        </div>          
                    </div>

                    {/* <input type='submit' value='Search' /> */}

                    {/* <Link to='/makeservercall' state={ searchCriteria }>
                        <i className="fa fa-search" aria-hidden="true" style={{ fontSize : "30px"}}></i>
                    </Link> */}

                </form>
            </div>
                        
            {/* <ServerCall /> */}

            {
                isLoading === true ? <div style={{ marginRight: 'auto', 
                                            marginLeft: 'auto', 
                                            position: 'relative', 
                                            top: '50%', 
                                            width: '69%',
                                            fontSize: '20px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            color: '#ff6464' }}>
                                    Loading ...
                            </div> 
                : 
                data && data.length > 0 && Array.isArray(data) ? 
                <div className='jobs-list' id='jobsList'>
                    {
                        data &&
                        Array.isArray(data) &&
                        data.map((job, key) => {
                            return(

                                <div key={ key } style={{ width: '100%' }}>
                                    <JobItem _jobItem={ job } />
                                </div>
                            )
                        })
                    }


                    <div className='pagination-block'>
                        {
                            linksNb &&
                            Array.isArray(linksNb) &&
                            linksNb.length > 1 &&    // there is more than 1 link
                            linksNb.map((link, index) => {
                                return(
                                    <div key={index}
                                        className={ activeLink === link ? 'active-link one-link' : 'one-link' }
                                        onClick={ () => getCorrentItems(link) }>{ link }</div>
                                )
                            })
                        }

                        {/* <span style={{ margin: '0 20px' }}>
                            [{ from } - { to }]
                        </span> */}

                        {/* <span>
                            <select value={ jobsPerPage } onChange={ _setJobsPerPage }>
                                <option value='3'>3</option>
                                <option value='5'>5</option>
                                <option value='10'>10</option>
                            </select> 
                        </span> */}
                    </div>   {/*  End pagination div */}


                {/*  End Jobs list div */}
                </div> :

                // data !== null && data.length === 0 ?   
                itemsNb === 0 && data !== null && data.length === 0  ? 
                <div className='empty-result' id='emptyResult'>
                    <div className='small-white-ball'>
                    </div>
                    <div className='empty-search-message'>
                        {/* There are no listings matching your search. */}
                        There are no result.
                    </div>
                </div> : ''
            }
        </div>
        <br/><br/>
        <Footer />


    </div>);
}






/*
import { useState } from 'react';
import './Search.css';

const Search = ({ placeholder, onChange }) => {
    //console.log("only search component has re-rendered");
    
    const [searchedText, setSearchText] = useState('');

    const removeSearchText = () => {
        setSearchText('');
        onChange('');
    }

    const searchText = (e) => {
        setSearchText(e.target.value);
        onChange(e.target.value);
    }

    return(
        <div className='search-container'>
            <i id="search-icon" className="fa fa-search" aria-hidden="true"></i>
            <input type='text'
                   className= 'search-input'
                   placeholder={ placeholder }
                   value={ searchedText }
                   onChange={ (e) => searchText(e) }/>
            { searchedText === '' ?
               '' : 
               <i id="clear-icon" 
               className="fa fa-times-circle-o"
               style={{ cursor: 'pointer' }}
               onClick={ removeSearchText }
               aria-hidden="true"></i>
            }
        </div>
    );
    
    
    /*
    *********
    Old code:
    *********
    return(
        <>
            <input type='checkbox' 
                   checked = { checking } 
                   onChange = { change } />
                   {`${checking}`} <br/><br/>
        </>
    );
}

*/

export default Search;

