


import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';

import './ApplicationSentSuccessfully.css';
import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';

const ApplicationSentSuccessfully = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [showPageContent, setShowPageContent] = useState(false);

    useEffect(() => {
        // If not Logged in.
        //if(!document.cookie)
        if(!Cookie.get('token'))
            navigate('/login');

        // If an "Employer" navigates to "sent_successfully" manually, 
        // then clicks back to details. 
        else {
            //if(getUserFromCookie(document.cookie).AccountType === 1) {  // here XXXX
            if(getUserFromCookie(Cookie.get('token')).AccountType === 1) {
                navigate('/');
            }
            else {
                //if(location && location.state && location.state.LastVisitedPage) {
                    //if(location.state.LastVisitedPage.indexOf('job/details/') >= 0) 
                        setShowPageContent(true);
                // }
                // else
                //     navigate(-1);
            }
        }
    }, []);

    //console.log("location", location);

    const back = () => {
        navigate(-1);
    }

    return(
        <>
            {
                //document.cookie && showPageContent &&
                Cookie.get('token') && showPageContent &&
                <div className="sent-successfully every-page-container">
                    <div>
                        <div className="loggedin-block">
                            Your application has been sent successfully. 
                            <button onClick={ back } className="back">
                                Back to Job Details
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ApplicationSentSuccessfully;
