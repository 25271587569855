

import axios from 'axios';
import './ChangePassword.css';
import { useState, useEffect } from 'react';
import { useToast } from '../../CustomeHooks/useToast';

import { getUserFromCookie, isValidToken } from '../../Javascript_Functions_to_export/Utils';
import Cookie from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [oldPassIsHidden, setOldPassIsHidden] = useState(true);
    const [newPassIsHidden, setNewPassIsHidden] = useState(true);
    const [confirmPassIsHidden, setConfirmPassIsHidden] = useState(true);


    const [passwordIsChanging, setPasswordIsChanging] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        //console.log("Cookie.get('token')=", Cookie.get('token'));
        
        // axios.get(`${process.env.REACT_APP_URL}/touch_session`)
        // .then(res => {
        //     console.log("sessionEnded=", res.data.sessionEnded, "expire=", res.data.expire);
        //     if(res.data.sessionEnded && res.data.sessionEnded === true) {
        //         console.log("should logout");
        //         alert("Session has expired.")
        //         if(localStorag.getItem('Logo'))
        //             localStorag.removeItem('Logo');
        //         window.location.href = process.env.REACT_APP_ORIGI + "/login";
        //     }
        // });
        
        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }
        
        // Flow when user clicks "Logout":
        // 1/ it goes to delete the cookie
        // 2/ then reload to "changePass" page
        // 3/ check the "cookie" and the "lastVisitedPage"

        // user navigate to this page manually (using url)
        if(!location.state && !Cookie.get('token')) {
            toast('warning', 'Please login first !');
            //navigate('/login');
            // use this line instead of navigate, because it could the cookie expired, 
            // so we need to reload the page to remove the companyLogo
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
        }
        else {
            // user already was in changePassword page, and clicks "Logout"
            if(location && location.state.LastVisitedPage === "/changepassword" && !Cookie.get('token')) {
                //navigate('/login');
                window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            }
        }
    }, []);


    const _setNewPassword = (e) => {
        setNewPassword(e.target.value);
        setNewPasswordStrength(evaluatePasswordStrength(e.target.value));
    
    }

    const _setConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmedPasswordStrength(evaluatePasswordStrength(e.target.value));
    }

    const [newPasswordStrength, setNewPasswordStrength] = useState('');
    const [confirmedPasswordStrength, setConfirmedPasswordStrength] = useState('');


    function evaluatePasswordStrength(password) {
        let score = 0;
    
        if (!password) return '';

        // Check password length
        if (password.length >= 8) score += 1;
        // Contains lowercase
        if (/[a-z]/.test(password)) score += 1;
        // Contains uppercase
        if (/[A-Z]/.test(password)) score += 1;
        // Contains numbers
        if (/\d/.test(password)) score += 1;
        // Contains special characters
        if (/[^A-Za-z0-9]/.test(password)) score += 1;


        switch (score) {
            case 0:
            case 1:
            case 2:
            return "weak";
          case 3:
          case 4:
              return "medium";
          case 5:
            return "strong";
          }
      }

    axios.defaults.withCredentials = true;

    //const handleSubmit = (e) => {
    const changePassword = () => {
        //e.preventDefault();

        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }

        // If user closes the tab || 
        //         closes the browser ||
        //         or open a new tab
        // before the session expired
        // if(_token && !sessionStorage.getItem('Logo')) {
        //     axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
        //     .then(res => {
        //         if(sessionStorage.getItem('Logo'))
        //             sessionStorage.removeItem('Logo');

        //         window.location.href = process.env.REACT_APP_ORIGI + "/login";
        //     })
        //     .catch(err => {
        //         toast('error', err.response.data.message);
        //     })
        // }
        
        // if(!Cookie.get('token')) {
        //     toast('error', 'You are not logged in !');
        //     return;
        // }

        // required
        if(!oldPassword || (oldPassword && oldPassword.trim() === '')) {
            toast('warning', 'Old Password is required !');
            return;
        }
        if(!newPassword || (newPassword && newPassword.trim() === '')) {
            toast('warning', 'New Password is required !');
            return;
        }
        if(!confirmPassword || (confirmPassword && confirmPassword.trim() === '')) {
            toast('warning', 'Confirm Password is required !');
            return;
        }


        // Strong or not
        if(newPasswordStrength !== 'strong') {
            toast('warning', 'New Password is not strong !');
            return;
        }
        if(confirmedPasswordStrength !== 'strong') {
            toast('warning', 'Confirm Password is not strong !');
            return;
        }


        //Matching or not
        if(newPassword !== confirmPassword) {
            toast('warning', 'New Password and Confirm Password did not matched.');
            return;
        }

        var data = {
            Username: getUserFromCookie(Cookie.get('token')).Username,
            OldPassword: oldPassword.trim(),
            NewPassword: newPassword.trim(),
            ConfirmPassword: confirmPassword.trim(), 
        }
        
        setPasswordIsChanging(true);

        //axios.put('http://localhost:808x/change_password', data)
        //axios.put(`${process.env.REACT_APP_URL}/change_password`, data)
        axios.post(`${process.env.REACT_APP_URL}/change_password`, data)
        .then(res => {
            
            if(res.data.status === 'success')
                toast('success', res.data.message);
            else
                toast('error', res.data.message);
            
        })
        .catch(err => {
            console.log('error', err);
        })
        .finally(() => {
            setPasswordIsChanging(false);
        })
    }

    const toggleVisibilityOld = () => {
        var x = document.getElementById("oldpass");
        if (x.type === "password") {
          x.type = "text";
          setOldPassIsHidden(false);
        } else {
          x.type = "password";
          setOldPassIsHidden(true);
        }
    }

    const toggleVisibilityNew = () => {
        var x = document.getElementById("newpass");
        if (x.type === "password") {
          x.type = "text";
          setNewPassIsHidden(false);
        } else {
          x.type = "password";
          setNewPassIsHidden(true);
        }
    }

    const toggleVisibilityConfirm = () => {
        var x = document.getElementById("confirmpass");
        if (x.type === "password") {
          x.type = "text";
          setConfirmPassIsHidden(false);
        } else {
          x.type = "password";
          setConfirmPassIsHidden(true);
        }
    }




    return(
    <div className='change-password-container every-page-container'>

    {
        //Cookie.get('token') && 
        <>
            <div className='title-section'>
                <div className='title'>
                    <h2>Change Password</h2>
                </div>
                <div className='path'>
                    <div className='home'>Menu</div>
                    <div className='circle'></div>
                    <div className='change-password'>Change Password</div>
                </div>
            </div>

            <div className='fields-section'>
                {/* <form onSubmit={ handleSubmit }> */}
                <form>

                    <div className='row-wrap'>
                        
                        <div className='pass-strength-section field-comp'>
                            <div className='comp-title'>                          
                            </div> 
                            <div className='field-password-strength'>
                                Reminder: Your password should be comprised of at least 8 characters. 
                                Use a combo of uppercase letters, lowercase letters, numbers, 
                                and even special characters (!, @, $, %, ^, &, *, +, #).
                            </div>
                        </div>
                        
                        <div className='old-pass-section field-comp-special'>
                            <div className='comp-title'>
                                Old Password <span style={{ color: 'red' }}>*</span>
                            </div> 
                            <div className='field-container'>
                                <i className="fa fa-lock lock-icon" aria-hidden="true"></i>
                                
                                {
                                    oldPassIsHidden ? 
                                    oldPassword && 
                                    <i className="fa fa-eye show-hide-eye-50"   //   50 : 50%
                                        aria-hidden="true"
                                        onClick={ toggleVisibilityOld }></i>
                                    :
                                    oldPassword && 
                                    <i className="fa fa-eye-slash show-hide-eye-50" 
                                        aria-hidden="true"
                                        onClick={ toggleVisibilityOld }></i>
                                }

                                <input name='oldpassword'
                                        type='password'
                                        id='oldpass'
                                        className='input-itself'
                                        maxLength='30'
                                        value={ oldPassword }
                                        placeholder='Enter your old password'
                                        onChange={ (e) => setOldPassword(e.target.value) }/>        
                            </div> 
                        </div>

                        <div className='new-pass-section field-comp'>
                            <div className='comp-title'>
                                <span>New Password </span><span style={{ color: 'red' }}>*</span>
                                <span className="mobile-none" style={{ fontWeight: '400',
                                                marginLeft: '15px',
                                                fontStyle: 'italic',
                                        color: newPasswordStrength === 'weak' ? 'red' :
                                        newPasswordStrength === 'medium' ? 'orange' : '#00b929' }}>
                                    { 
                                        newPasswordStrength ? 
                                        " Strength : " + newPasswordStrength
                                        : 
                                        '' 
                                    }
                                    {
                                        newPasswordStrength && newPasswordStrength === 'strong' ? 
                                        <span style={{ marginLeft: '10px', }}> &#10004; </span>
                                        : 
                                        ''
                                    }
                                </span>
                            </div> 
                            <div className='field-container'>
                                <i className="fa fa-lock lock-icon" aria-hidden="true"></i>

                                {
                                    newPassIsHidden ? 
                                    newPassword && 
                                    <i className="fa fa-eye show-hide-eye-50"   //   50 : 50%
                                        aria-hidden="true"
                                        onClick={ toggleVisibilityNew }></i>
                                    :
                                    newPassword && 
                                    <i className="fa fa-eye-slash show-hide-eye-50" 
                                        aria-hidden="true"
                                        onClick={ toggleVisibilityNew }></i>
                                }

                                <input name='newPassword'
                                        type='password'
                                        maxLength='30'
                                        id='newpass'
                                        className='input-itself'
                                        placeholder='Enter your new password'
                                        value={ newPassword }
                                        onChange={ _setNewPassword }/> <br/><br/>       
                            </div>
                        </div>

                        <div className='confirm-pass-section field-comp' style={{ marginTop: '25px' }}>
                            <div className='comp-title'>
                                <span>Confirm New Password </span><span style={{ color: 'red' }}>*</span>
                                <span className="mobile-none" style={{ fontWeight: '400',
                                                marginLeft: '15px',
                                                fontStyle: 'italic',
                                        color: confirmedPasswordStrength === 'weak' ? 'red' :
                                        confirmedPasswordStrength === 'medium' ? 'orange' : '#00b929' }}>
                                    { 
                                        confirmedPasswordStrength ? 
                                        " Strength : " + confirmedPasswordStrength
                                        : 
                                        '' 
                                    }
                                    {
                                        confirmedPasswordStrength && confirmedPasswordStrength === 'strong' ? 
                                        <span style={{ marginLeft: '10px', }}> &#10004; </span>
                                        : 
                                        ''
                                    }
                                </span>
                            </div> 
                            <div className='field-container'>
                                <i className="fa fa-lock lock-icon" aria-hidden="true"></i>

                                {
                                    confirmPassIsHidden ? 
                                    confirmPassword && 
                                    <i className="fa fa-eye show-hide-eye-100"   //   100 : 100%
                                        aria-hidden="true"
                                        onClick={ toggleVisibilityConfirm }></i>
                                    :
                                    confirmPassword && 
                                    <i className="fa fa-eye-slash show-hide-eye-100" 
                                        aria-hidden="true"
                                        onClick={ toggleVisibilityConfirm }></i>
                                }


                                <input name='confirmNewPassword'
                                        type='password'
                                        id='confirmpass'
                                        className='input-itself'
                                        maxLength='30'
                                        placeholder='Confirm your new password'
                                        value={ confirmPassword }
                                        onChange={ _setConfirmPassword }/>
                            </div>
                        </div>

                        <div className='btn-save field-comp'>
                            
                        <input name='changepassword'
                                type='button'
                                onClick={ changePassword }
                                className={`submit-btn-itself ${ passwordIsChanging === true ? 'disabled' : ''}`}
                                value={`${ passwordIsChanging === true ? 'Changing ...' : 'Change Password'}`} 
                                disabled={ passwordIsChanging === true } />
                            
                            {/* { 
                                !passwordIsChanging && 
                                <input name='changepassword'
                                    type='submit' 
                                    className='submit-btn-itself'
                                    value='Change Password' />
                            } */}
                        </div>

                    </div>

                </form>
            </div>
        </>
    }
    
    </div>)
}

export default ChangePassword;